<template>
  <b-navbar data-cy="navbar" toggleable="md" type="dark" class="navbar navbar-expand-lg navbar-dark">
    <b-navbar-brand class="logo" b-link to="/">
      <span v-if="companyInfo == null"></span>
      <span class="logo-img" v-if="company.icon == null"></span>
      <img class="logo-img" v-if="company.icon" :src="company.icon ? company.icon : '../../../content/images/logo-jhipster.png'" alt="" />
      <!--      <span v-text="$t('global.title')" class="navbar-title">CandyCRM</span>-->
      <!--      <span v-text="company.name ? company.name : $t('global.title')" class="navbar-title">CandyCRM</span>-->
      <!--<span class="navbar-version">{{ version }}</span>-->
    </b-navbar-brand>

    <b-navbar-toggle
      right
      class="jh-navbar-toggler d-lg-none"
      href="javascript:void(0);"
      data-toggle="collapse"
      target="header-tabs"
      aria-expanded="false"
      aria-label="Toggle navigation"
    >
      <font-awesome-icon icon="bars" />
    </b-navbar-toggle>

    <b-collapse is-nav id="header-tabs">
      <!--      <b-navbar-nav class="ml-auto">-->
      <!--      菜单靠左-->
      <b-navbar-nav>
        <!--        用作与占位，但是不显示-->
        <b-nav-item  v-if="!authenticated" style="visibility: hidden" exact>
          <span>
            <font-awesome-icon icon="home" />
            <span v-text="$t('global.menu.home')">Home</span>
          </span>
        </b-nav-item>

        <!-- <b-nav-item :class="{
          active: getActive('Home')
        }" :to="{ name: 'Home' }" v-if="authenticated" exact>
          <span>
            <font-awesome-icon icon="home" />
            <span v-text="$t('global.menu.home')">Home</span>
          </span>
        </b-nav-item> -->
            <b-nav-item
          :to="{ name: 'Customer' }"
          :class="{
          actived: getActive('jijiwaiwai')
        }"
          v-if="authenticated && (hasAnyAuthority('ROLE_COMPANY_ADMIN') || hasAnyAuthority('ROLE_USER'))"
        >
          <font-awesome-icon icon="users" />
          <span v-text="$t('global.menu.entities.customer')">Customer</span>
        </b-nav-item>
        <!--        公司管理-->
        <b-nav-item :class="{
          active: getActive('Company')
        }" :to="{ name: 'Company' }" v-if="hasAnyAuthority('ROLE_ADMIN') && authenticated">
          <!--          <font-awesome-icon icon="asterisk" />-->
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-building" viewBox="0 0 16 16">
            <path
              fill-rule="evenodd"
              d="M14.763.075A.5.5 0 0 1 15 .5v15a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5V14h-1v1.5a.5.5 0 0 1-.5.5h-9a.5.5 0 0 1-.5-.5V10a.5.5 0 0 1 .342-.474L6 7.64V4.5a.5.5 0 0 1 .276-.447l8-4a.5.5 0 0 1 .487.022zM6 8.694 1 10.36V15h5V8.694zM7 15h2v-1.5a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 .5.5V15h2V1.309l-7 3.5V15z"
            />
            <path
              d="M2 11h1v1H2v-1zm2 0h1v1H4v-1zm-2 2h1v1H2v-1zm2 0h1v1H4v-1zm4-4h1v1H8V9zm2 0h1v1h-1V9zm-2 2h1v1H8v-1zm2 0h1v1h-1v-1zm2-2h1v1h-1V9zm0 2h1v1h-1v-1zM8 7h1v1H8V7zm2 0h1v1h-1V7zm2 0h1v1h-1V7zM8 5h1v1H8V5zm2 0h1v1h-1V5zm2 0h1v1h-1V5zm0-2h1v1h-1V3z"
            />
          </svg>
          <span v-text="$t('global.menu.entities.company')">Company</span>
        </b-nav-item>

        <!--          用户管理-->
        <b-nav-item
          :to="{ name: 'JhiUser' }"
          :class="{
          active: getActive('JhiUser')
          }"
          v-if="authenticated && (hasAnyAuthority('ROLE_COMPANY_ADMIN') || hasAnyAuthority('ROLE_ADMIN'))"
        >
          <font-awesome-icon icon="users" />
          <span v-text="$t('global.menu.admin.userManagement')">User management</span>
        </b-nav-item>

        <!--        客户管理-->
    

        <!--        操作记录-->
        <b-nav-item :to="{ name: 'OperationLog' }"
        :class="{
          active: getActive('OperationLog')
        }"
         v-if="authenticated && hasAnyAuthority('ROLE_COMPANY_ADMIN')">
          <font-awesome-icon icon="list" />
          <span v-text="$t('global.menu.entities.operationLog')">Operation Log</span>
        </b-nav-item>

        <!--系统设置   -->
        <b-nav-item :to="{ name: 'SystemSetting' }"
        :class="{
          active: getActive('SystemSetting')
        }"
         v-if="authenticated && hasAnyAuthority('ROLE_COMPANY_ADMIN')">
          <font-awesome-icon icon="cogs" />
          <span v-text="$t('global.menu.entities.systemSetting')">System Setting</span>
        </b-nav-item>

        <!--<b-nav-item-dropdown right id="entity-menu" v-if="authenticated" active-class="active" class="pointer" data-cy="entity">
          <span slot="button-content" class="navbar-dropdown-menu">
            <font-awesome-icon icon="th-list" />
            <span class="no-bold" v-text="$t('global.menu.entities.main')">Entities</span>
          </span>
          <entities-menu></entities-menu>
          &lt;!&ndash; jhipster-needle-add-entity-to-menu - JHipster will add entities to the menu here &ndash;&gt;
        </b-nav-item-dropdown>-->

        <!--<b-nav-item-dropdown
          right
          id="admin-menu"
          v-if="(hasAnyAuthority('ROLE_COMPANY_ADMIN') || hasAnyAuthority('ROLE_ADMIN')) && authenticated"
          :class="{ 'router-link-active': subIsActive('/admin') }"
          active-class="active"
          class="pointer"
          data-cy="adminMenu"
        >
          <span slot="button-content" class="navbar-dropdown-menu">
            <font-awesome-icon icon="users-cog" />
            <span class="no-bold" v-text="$t('global.menu.admin.main')">Administration</span>
          </span>

          &lt;!&ndash;          用户管理&ndash;&gt;
          <b-dropdown-item :to="{ name: 'JhiUser' }" active-class="active">
            <font-awesome-icon icon="users" />
            <span v-text="$t('global.menu.admin.userManagement')">User management</span>
          </b-dropdown-item>

          &lt;!&ndash; 资源监控 &ndash;&gt;
          &lt;!&ndash; <b-dropdown-item to="/admin/metrics" active-class="active">
            <font-awesome-icon icon="tachometer-alt" />
            <span v-text="$t('global.menu.admin.metrics')">Metrics</span>
          </b-dropdown-item>&ndash;&gt;

          &lt;!&ndash;          服务状态&ndash;&gt;
          &lt;!&ndash;<b-dropdown-item to="/admin/health" active-class="active">
            <font-awesome-icon icon="heart" />
            <span v-text="$t('global.menu.admin.health')">Health</span>
          </b-dropdown-item>&ndash;&gt;

          &lt;!&ndash;          配置&ndash;&gt;
          &lt;!&ndash;<b-dropdown-item to="/admin/configuration" active-class="active">
            <font-awesome-icon icon="cogs" />
            <span v-text="$t('global.menu.admin.configuration')">Configuration</span>
          </b-dropdown-item>&ndash;&gt;
          &lt;!&ndash;          日志&ndash;&gt;
          &lt;!&ndash;<b-dropdown-item to="/admin/logs" active-class="active">
            <font-awesome-icon icon="tasks" />
            <span v-text="$t('global.menu.admin.logs')">Logs</span>
          </b-dropdown-item>&ndash;&gt;

          &lt;!&ndash;          api文档&ndash;&gt;
          &lt;!&ndash;<b-dropdown-item v-if="openAPIEnabled" to="/admin/docs" active-class="active">
            <font-awesome-icon icon="book" />
            <span v-text="$t('global.menu.admin.apidocs')">API</span>
          </b-dropdown-item>&ndash;&gt;
        </b-nav-item-dropdown>-->

        <b-nav-item-dropdown id="languagesnavBarDropdown" right v-if="languages && Object.keys(languages).length > 1">
          <span slot="button-content">
            <font-awesome-icon icon="flag" />
            <span class="no-bold" v-text="$t('global.menu.language')">Language</span>
          </span>
          <b-dropdown-item
            v-for="(value, key) in languages"
            :key="`lang-${key}`"
            v-on:click="changeLanguage(key)"
            :class="{ active: isActiveLanguage(key) }"
          >
            {{ value.name }}
          </b-dropdown-item>
        </b-nav-item-dropdown>

        <b-nav-item-dropdown
          right
          href="javascript:void(0);"
          id="account-menu"
          :class="{ 'router-link-active': subIsActive('/account') }"
          active-class="active"
          class="pointer"
          data-cy="accountMenu"
        >
          <span slot="button-content" class="nav-item b-nav-dropdown dropdown pointer" v-if="this.$store.getters.account">
            <svg
              v-if="hasAnyAuthority('ROLE_COMPANY_ADMIN') || hasAnyAuthority('ROLE_ADMIN')"
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="#333"
              class="bi bi-person-lines-fill"
              viewBox="0 0 16 16"
            >
              <path
                d="M6 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm-5 6s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H1zM11 3.5a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 0 1h-4a.5.5 0 0 1-.5-.5zm.5 2.5a.5.5 0 0 0 0 1h4a.5.5 0 0 0 0-1h-4zm2 3a.5.5 0 0 0 0 1h2a.5.5 0 0 0 0-1h-2zm0 3a.5.5 0 0 0 0 1h2a.5.5 0 0 0 0-1h-2z"
              />
            </svg>
            <span class="no-bold"> {{ this.$store.getters.account ? this.$store.getters.account.firstName : '' }} </span>&nbsp;&nbsp;
          </span>
          <span slot="button-content"  v-else class="navbar-dropdown-menu">
            <font-awesome-icon icon="user" style="color:#333"/>
            <span class="no-bold" v-text="$t('global.menu.account.main')"> Account </span>
          </span>
          <b-dropdown-item data-cy="settings" :to="{ name: 'Settings' }" tag="b-dropdown-item" v-if="authenticated">
            <font-awesome-icon icon="wrench" />
            <span v-text="$t('global.menu.account.settings')">Settings</span>
          </b-dropdown-item>
          <b-dropdown-item data-cy="passwordItem" :to="{ name: 'ChangePassword' }" tag="b-dropdown-item" v-if="authenticated">
            <font-awesome-icon icon="lock" />
            <span v-text="$t('global.menu.account.password')">Password</span>
          </b-dropdown-item>
          <b-dropdown-item data-cy="logout" v-if="authenticated" v-on:click="logout()" id="logout" active-class="active">
            <font-awesome-icon icon="sign-out-alt" />
            <span v-text="$t('global.menu.account.logout')">Sign out</span>
          </b-dropdown-item>
          <b-dropdown-item data-cy="login" v-if="!authenticated" v-on:click="openLogin()" id="login" active-class="active">
            <font-awesome-icon icon="sign-in-alt" />
            <span v-text="$t('global.menu.account.login')">Sign in</span>
          </b-dropdown-item>

          <!--<b-dropdown-item
            data-cy="register"
            to="/register"
            tag="b-dropdown-item"
            id="register"
            v-if="!authenticated"
            active-class="active"
          >
            <font-awesome-icon icon="user-plus" />
            <span v-text="$t('global.menu.account.register')">Register</span>
          </b-dropdown-item>-->
        </b-nav-item-dropdown>
      </b-navbar-nav>
    </b-collapse>
  </b-navbar>
</template>

<script lang="ts" src="./jhi-navbar.component.ts"></script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/* ==========================================================================
    Navbar
    ========================================================================== */
.navbar  {
  padding: 0.1rem 1rem;
  background-color: #fff;
  color: #333;
  height: 43px;
  line-height: 43px;
}
.navbar-version {
  font-size: 10px;
  color: #ccc;
}

.jh-navbar {
  background-color: #353d47;
  padding: 0.2em 1em;
}

.jh-navbar .profile-image {
  margin: -10px 0px;
  height: 40px;
  width: 40px;
  border-radius: 50%;
}

.jh-navbar .dropdown-item.active,
.jh-navbar .dropdown-item.active:focus,
.jh-navbar .dropdown-item.active:hover {
  background-color: #353d47;
}

.jh-navbar .dropdown-toggle::after {
  margin-left: 0.15em;
}

.jh-navbar ul.navbar-nav {
  padding: 0.5em;
}

.jh-navbar .navbar-nav .nav-item {
  margin-left: 1.5rem;
}

.jh-navbar a.nav-link,
.jh-navbar .no-bold {
  font-weight: 400;
}
.nav-link,.no-bold{
  color: #333 !important;
}

.jh-navbar .jh-navbar-toggler {
  color: #ccc;
  font-size: 1.5em;
  padding: 10px;
}

.jh-navbar .jh-navbar-toggler:hover {
  color: #fff;
}

@media screen and (min-width: 768px) {
  .jh-navbar-toggler {
    display: none;
  }
}

@media screen and (min-width: 768px) and (max-width: 1150px) {
  span span {
    display: none;
  }
}

.navbar-title {
  display: inline-block;
  vertical-align: middle;
  color: white;
}

/* ==========================================================================
    Logo styles
    ========================================================================== */
.navbar-brand.logo {
  padding: 0px 15px;
  display: flex;
}
.dropdown-toggle{
  padding: 0.2rem !important;
}
.logo .logo-img {

  display: inline-block;
  vertical-align: middle;
  height: 25px;
  width: auto;
}
.navbar-nav .nav-link {
  padding: 0.2rem;
}

.logo-img {
  height: 100%;
  background: url('../../../content/images/logo-jhipster.png') no-repeat center center;
  background-size: contain;
  width: 100%;
  filter: drop-shadow(0 0 0.05rem white);
}
#account-menu {
  position: absolute;
  right: 20px;
}
ul.navbar-nav {
  width: 100%;
}
</style>
